import logo from "./logo.svg";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import Aboutus from "./Components/Header/Aboutus";
import OurModel from "./Components/Header/OurModel";
import ContactUs from "./Components/Header/ContactUs";

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Aboutus />} />
        <Route path="/ourmodel" element={<OurModel />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
    </div>
  );
}

export default App;
