import React from "react";

function OurModel() {
  return (
    <div style={{ textAlign: "left", margin: "80px" }}>
      <h1 style={{ color: "rgb(62, 62, 149)" }}>Our Model</h1>
      <h2 style={{ fontWeight: "700" }}>Speed to Market</h2>
      <p>
        Our development experience allows us to rapidly produce prototypes and
        MVP products which gets you in font of buyers faster.
      </p>

      <h2 style={{ fontWeight: "700" }}>Preserve Your Equity</h2>
      <p>
        With a technical co-founder, you might give up 49% of your business just
        to work with someone less experienced.
      </p>

      <h2 style={{ fontWeight: "700" }}>The Right Investment</h2>
      <p>
        Big investors come with lots of strings attached. Our investment model
        is simple small initial investment in exchange for marginal equity.
      </p>
    </div>
  );
}

export default OurModel;
