import React from "react";
import "./Header.css";
import logo from "../Images/abr-logo-small.png";

function Header() {
  return (
    <div className="Header">
      <nav className="nav-display">
        <img src={logo} alt="logo" className="logo-img" />
        <div className="nav-container">
          <a href="/"> About Us</a>
          <a href="/ourmodel"> Our Model</a>
          <a href="/contactus"> Contact Us </a>
        </div>
      </nav>
    </div>
  );
}

export default Header;
