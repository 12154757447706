import React from "react";

function ContactUs() {
  return (
    <div style={{ textAlign: "left", margin: "80px" }}>
      <h1 style={{ color: "rgb(62, 62, 149)" }}>Contact Us:</h1>
      <p style={{fontWeight:"600"}}>162 Michigan Dr Bloomingdale, IL 60108</p>
      <p style={{fontWeight:"600"}}>Phone: 630.760.8008</p>
    </div>
  );
}

export default ContactUs;
