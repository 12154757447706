import React from "react";
import "./style.css";

function Aboutus() {
  return (
    <div className="page-container">
      <h1 className="about-us-heading">Welcome to ABR Holdings</h1>
      <div className="about-us-content">
        <p>Make Your Startup Dream Come true.</p>
        <p>Have a Startup Idea?</p>
        <p>We can make it a reality together</p>
      </div>
      <div style={{ marginTop: "55px" }}>
        <h1 className="about-us-heading">WHO WE ARE</h1>
        <h1 className="about-us-heading">
          By Entrepreneurs,
          <br /> For Entrepreneurs
        </h1>
        <p className="about-us-content">
          Not every startup is a unicorn … and that’s okay. We help workhorse
          startups solve real problems and scale to tens of millions in annual
          revenue or more.
        </p>
        <p className="about-us-content">
          We’re entrepreneurs who know tech. We’ve been there, done that, and
          done it well repeatedly through our careers. Of course, we’ve made
          mistakes along the way—mistakes we can help you avoid.
        </p>
        <p className="about-us-content">
          We deliver seed money, development resources, and the advice you need
          to turn your workhorse concept into a viable product, take it to
          market, and scale up.
        </p>
      </div>
    </div>
  );
}

export default Aboutus;
